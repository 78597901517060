import React from 'react'
import Layout from '../components/layout'
import Logo from '../components/homepage/logo'

class Homepage extends React.Component {
  componentDidMount() {
  }
  
  render () {
    return (
      <Layout>
        <div className="l-wrapper">
          <div className="l-container u-horizontal u-space-between">
            <div className="l-half">
              <Logo />
            </div>
            <div className="l-half">
              <p className="print-only">
                <strong>Pietro Di Marzo</strong>
                <br />
                <strong>Born: </strong> 21/04/1986
                <br />
                <strong>Email: </strong> pietrodimarzo@gmail.com
                <br />
                <strong>Phone: </strong> +61401755253
                <br />
                <strong>Website: </strong> www.nerdskullz.com
              </p>
            </div>
          </div>
        </div>
        <div className="l-wrapper">
          <div className="l-container">
          <p>
              Dear AustraliaPost,
              <br />
              I am writing to apply for the <strong>“Senior Front End Developer”</strong> position you posted online.
              <br />
              For my current employer, I work as a Technical Lead; my skills include a deep knowledge of HTML, CSS, React as well as leading a team of 4 Full Stack Developers based in Australia and overseas, being proactive in developing new products for the business, providing technical leadership across a multi functional team, ensuring projects are delivered with the highest code standard, in time and within the budget.
              </p>
              <p>
                I really do feel I’m a suitable candidate for this post: alongside my technical skills, I have a proven experience of working in an agency and have always been a team player. My thoroughness in my work is probably my best feature - I love all my projects to be perfectly and neatly carried out. I have always been known as a resource to rely on when it comes to work the whole night to get the job done, and I’m not afraid of strict deadlines. My latest projects have seen me helping the business getting rid of their tech debt developing both a new infrastructure leveraging the newest web technologies (Gatsby, NextJs, React) as well as a way to integrate and push forward best practices in developing future proof product in the existing infrasturcture..
              </p>
              <p>
                My expertise covers different fields of work, which makes me a good problem solver in plenty of different everyday situations that can occur within an agency: I am always happy to step in and help my team, and everyone knows me as a reliable and trustworthy resource.
              </p>
              <p>
                I consistently strive towards learning new skills and developments in technology to improve myself and my work.
                The user experience course at General Assembly thought me how important it is to deeply understand the problem before committing to the right solution.
                As a web/IT enthusiast, passion is what drives me most: I’m always up to date with new technologies and softwares.
              </p>
              <p>
                To make it even more simple: this is just my dream job. I go to work everyday feeling that I’m doing what I like and thinking about how to do it better - and I feel that my passion and eagerness to “make things work just as I want them to” is what can actually make a difference - what makes me deliver high-quality projects for you clients.
              </p>
              <p>
                I am currently based in Melbourne, Victoria; I am an Australian citizen and I can't wait to start on this new exciting opportunity.
              </p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Homepage